import React from "react";
import Welcome from "./Welcome";
import List from "./List";
import Photos from "./Photos";

const Homepage = () => {

    return ( 
        <div>
            <Welcome id='welcome'/>
            <List id='lista'/>
            <Photos/>
        </div>

     );
}
 
export default Homepage;