import Header from "./Header";
import Homepage from "./Homepage";
import Items from './Items'
import Item from './Item'
import Admin from "./Admin";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function App() {

  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path='/' element={<Homepage/>}/>
          <Route path='list' element={<Items/>}/>
          <Route path='/item/:id' element={<Item/>}/>
          <Route path ='/admin-ver-presentes' element={<Admin/>}/>
        </Routes> 
      </div>
    </Router>
  );
}

export default App;
