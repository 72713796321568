import { useState, useEffect } from 'react';
import Loading from './Loading';


const Items = () => {

    const [lista, setLista] = useState([])
    
    useEffect(() => {
        fetch('https://wedding-api-zeta.vercel.app/api/gifts')
        .then(res => res.json())
        .then(data => setLista(data))
    }, [])

    return ( 
        <div className='items-wrapper'>
           {(lista.length !== 0 && <div className="items-list">
                {lista.map((item) => 
                    (item.buyer === "" && (
                    <div className='items-each'> 
                        <div className='image-wrapper'><img src={item.image} alt="" /></div>
                        <p>{item.name}</p>
                        <a href={`/item/${item.id}`} className='link-buy'>Escolher item</a>
                    </div>
                    ))
                    ||
                    (item.buyer !== "" && (
                        <div className='items-each' style={{backgroundColor: '#d3d2d0'}}> 
                            <div className='image-wrapper'><img src={item.image} alt="" /></div>
                            <p>{item.name}</p>
                            <p className='link-buy' style={{backgroundColor: '#979797'}}>Item comprado</p>
                        </div>
                    ))
                )}
            </div>) ||<Loading /> }
            <div className="bottom-photos">
                <div className="line" />
                <span className="weeding-date">27/04/2024</span>
                <div className="line" />
            </div>
        </div>
     );
}
 
export default Items;