import { useEffect, useState } from "react"
import Loading from "./Loading"

const Admin = () => {

    const [items, setItems] = useState([])

    useEffect(() => {
        fetch(`https://wedding-api-zeta.vercel.app/api/admin/`)
        .then(res => res.json())
        .then(data => {
            setItems(data)
        })
    }, [])

    const [confirmContainer, setConfirmContainer] = useState(false)
    const [watchItem, setWatchItem] = useState()
    const [watchItemIndex, setWatchItemIndex] = useState()

    const setConfirm = () => {
        console.log(watchItem.buy_id)
        fetch(`https://wedding-api-zeta.vercel.app/api/buyers/${watchItem.buy_id}` , {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              method: 'PATCH',                                                              
              body: JSON.stringify( { id: watchItem.buy_id, confirm: 1} )  
        })
        setConfirmContainer(false)
        items[watchItemIndex].confirm = 1;
    }

    return ( 
        <div className="admin-wrapper">
                {(items.length !== 0 && <div className="admin-div">
                    {items.map((item, index) => (
                        <div className="admin-item"> 
                            <div className="admin-item-wrapper">
                                <img src={item.image} height='80%' alt="" />
                                <p>{item.name}</p>
                            </div>
                            <p className="admin-item-buyer">{item.buyer}</p>
                            {
                                (!item.confirm &&
                                <button onClick={() => {setConfirmContainer(true); setWatchItem(item); setWatchItemIndex(index)}}>Confirmar</button>)
                                ||
                                <div className="checked-item">Confirmado</div>
                            }
                        </div>
                    ))}
                </div>)
                || <Loading/>
                }

            {confirmContainer && (
                <div className='confirm-div2'>
                    <div className='dark-background' onClick={() => setConfirmContainer(false)}/>
                    <div className="admin-confirm-container">
                        <div className="admin-texts-wrapper">
                            <p>Você deseja confirmar que {watchItem.buyer} fez o pagamento do item {watchItem.name}?</p>
                            <p>Essa ação não poderá ser desfeita.</p>
                        </div>
                        <button onClick={() => setConfirm()}>Confirmar</button>
                    </div>
                </div>
            )}
        </div>
     );
}
 
export default Admin;